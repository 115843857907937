import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';

interface IdName {
	id: number;
	name: string;
}

let listId = 0;

@autoinject
export class BelAuHtmlSelectMultiCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValues?: any[];
  @bindable({ defaultBindingMode: bindingMode.toView }) content: any[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) placeholder = "";
  @bindable({ defaultBindingMode: bindingMode.toView }) required = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) readOnly = false;
  @bindable({ defaultBindingMode: bindingMode.oneTime }) labelKey = "name";
  @bindable({ defaultBindingMode: bindingMode.oneTime }) idKey = "id";

  private listId: string;
  private value = "";

  constructor(private element: Element) {
    this.listId = "listMulti" + ++listId;
  }

  buildOptionString(choice: any) {
    return (choice[this.idKey] + " " + choice[this.labelKey]).toLowerCase();
  }

  /** Launched onInput, which is basically selecting something off the datalist
   * Does trigger on typing, at the start, should not usually find anything
  */
  onInput() {
    let found = this.content.find(choice => this.buildOptionString(choice) == this.value.toLowerCase());
    if(found) {
      this.addItem(this.value);
    }
  }

  /** If we have only one (even partial) match, add it. If more, autofill the first one but require + */
  focusEnd() {
    if (this.value == "") {
      return;
    }
    let options = this.content.filter(choice => this.buildOptionString(choice).includes(this.value.toLowerCase()));
    if (options.length == 1) {
      return this.addItem(this.buildOptionString(options[0]));
    }
    if (options.length) {
      this.value = this.buildOptionString(options[0]);
    }
  }

  addItem(itemString?: any) {
    if (!itemString) {
      return;
    }
    // ! HOX - Heavily relays, that the idKey field does *NOT* contain spaces...
    let id = itemString.split(" ")[0];
    if (this.selectedValues?.find(x => x[this.idKey] == id)) {
      return;
    }
    const item = this.content.find(x => x[this.idKey] == id);
    if (!item) {
      return;
    }
    this.selectedValues = [...this.selectedValues || [], { id: item[this.idKey], name: item[this.labelKey] }];
    this.value = "";
  }

  removeItem(item: IdName) {
    // If removeButtons reside in start of form, they can capture click. So we check, that the element that was active is a button
    // This should at least tackle most obvious issues of pressing enter on some input field leading to unwanted relation removal
    if (!(document.activeElement instanceof HTMLButtonElement)) {
      return;
    }
    this.selectedValues = this.selectedValues?.filter(p => p !== item);
  }

  @computedFrom("selectedValues")
  get readOnlyList() {
    return this.selectedValues?.map(x => x.name).join(", ");
  }
}
