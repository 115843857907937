// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `client-select div.controls {
  margin: 0.5em 0;
  display: flex;
}
client-select div.controls button#control-delete-button {
  border-left: 1px black solid;
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  color: black;
  background-color: white;
}
client-select div.controls input {
  border: 1px black solid;
}
`, "",{"version":3,"sources":["webpack://./src/components/client-select.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,aAAA;AAAJ;AAHA;EAMM,4BAAA;EACA,2BAAA;EACA,8BAAA;EACA,YAAA;EACA,uBAAA;AAAN;AAVA;EAcM,uBAAA;AADN","sourcesContent":["client-select {\n  div.controls {\n    margin: 0.5em 0;\n    display: flex;\n\n    button#control-delete-button {\n      border-left: 1px black solid;\n      border-top: 1px black solid;\n      border-bottom: 1px black solid;\n      color: black;\n      background-color: white;\n    }\n\n    input {\n      border: 1px black solid;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
