import { bindable, bindingMode } from 'aurelia-framework';

export class BelAuModalCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) visible = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) disableClose = false;

  hide(event: Event | undefined) {
    if (!event) {
      this.visible = false;
      return;
    }

    if (event.target instanceof HTMLDivElement) {
      if (event.target.className === "modal") {
        this.visible = false;
        return;
      }
    }
  }
}
