
import { autoinject, bindable, bindingMode, observable } from 'aurelia-framework';

let idGen = 0;

@autoinject
export class BelAuInputMonthCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) year?: number;
  /* N.B. implicitly also @observable, see monthChanged() */
  @bindable({ defaultBindingMode: bindingMode.twoWay }) month?: number;
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;
  @observable()
  monthLocal = "";
  @observable()
  yearLocal = new Date().getFullYear();

  idYear = "input-month-" + ++idGen;

  monthChanged() {
    if(this.month) {
      this.monthLocal = this.month?.toString();
    }
  }

  monthLocalChanged() {
    this.month = this.monthLocal ? parseInt(this.monthLocal) : undefined;
  }

  yearChanged() {
    if (this.year) {
      this.yearLocal = this.year;
      if (this.year > 2100) {
        this.yearLocal = 2100;
      }
    }
  }

  yearLocalChanged() {
    if (this.yearLocal.toString().length == 4) {
      this.year = this.yearLocal;
    }
  }
}
