import { bindingMode } from "aurelia-binding";
import { autoinject, bindable, computedFrom, observable } from "aurelia-framework";
import { I18N } from 'aurelia-i18n';
import { Country, MyHttpApi } from 'utils/api';
let countryCache: Country[] = [];
let countryCacheFetched = false;
let listId = 0;

@autoinject
export class Iso3166CountryCustomElement {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) value?: string;
	@bindable({ defaultBindingMode: bindingMode.toView }) class?: string;
	@bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;
	@bindable({ defaultBindingMode: bindingMode.toView }) placeholder?: string;
	@bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;
	@bindable({ defaultBindingMode: bindingMode.toView }) size?: number;

  private listId: string;

  @observable()
  valueLocal = "";

  content = countryCache;
  lang: "fi" | "sv" | "en" = "fi";

  constructor(private i18n: I18N, private api: MyHttpApi) {
    this.listId = "iso3166CountryList" + ++listId;
  } 
  
  async attached() {
    this.lang = this.i18n.getLocale() as typeof this.lang;
    if (!countryCacheFetched) {
      countryCacheFetched = true;
      let countries = await this.api.countryList();
      for (let c of countries) {
        countryCache.push(c);
      }
    }
    /** Update the text field. */
    this.valueChanged();
  }

  @computedFrom("content.length")
  get contentDynamic() {
    let content = [...this.content];
    content.sort((a, b) => {
      const valueA = (a[this.lang] ?? "").toLowerCase();
      const valueB = (b[this.lang] ?? "").toLowerCase();
      return valueA.localeCompare(valueB);
    });
    return content;
  }

  /** If we have only one (even partial) match, select it. */
  focusEnd() {
    if (this.valueLocal == "" || ! this.content) {
      this.value = undefined;
      return;
    }
    let exactMatch = this.content.find(c => c[this.lang] === this.valueLocal);
    let results = this.contentDynamic.filter(c => c[this.lang]?.toLowerCase().includes(this.valueLocal.toLowerCase()));
    if(exactMatch) {
      this.value = exactMatch.cc3;
    } else if (results.length == 1) {
      this.value = results[0].cc3;
    } else {
      this.value = undefined;
    }
  }

  onInput() {
    if(!this.content) {
      return;
    }
    let found = this.content.find(c => c[this.lang] === this.valueLocal);
    this.value = found?.cc3;
  }

  valueChanged() {
    this.valueLocal = this.content?.find(c => c.cc3 === this.value)?.[this.lang] ?? "";
  }

  clear() {
    this.valueLocal = "";
    this.value = undefined;
  }
}
