import { autoinject, bindable, bindingMode, observable } from 'aurelia-framework';
import { Client, MyHttpApi } from 'utils/api';

let idGen = 0;
let listId = 0;

@autoinject
export class ClientSelectCustomElement {
  /* N.B. implicitly also @observable, see valueChanged() */
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: number;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) content?: Client[];
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;

  private id = "client-select-" + ++idGen;
  private listId: string;

  @observable()
  valueLocal = "";

  constructor(private api: MyHttpApi) {
    this.listId = "clientSelectList" + ++listId;
  }

  async attached() {
    if (!this.content) {
      /** Default */
      this.content = await this.api.clientList();
    }
    /** Update the text field. */
    this.valueChanged();
  }

  /** If we have only one (even partial) match, select it. */
  focusEnd() {
    if (this.valueLocal == "" || ! this.content) {
      this.value = undefined;
      return;
    }
    let exactMatch = this.content.find(c => c.nickname == this.valueLocal);
    let results = this.content.filter(c => c.nickname.toLowerCase().includes(this.valueLocal.toLowerCase()));
    if(exactMatch) {
      this.value = exactMatch.id;
    } else if (results.length == 1) {
      this.value = results[0].id;
    } else {
      this.value = undefined;
    }
  }

  onInput() {
    if(!this.content) {
      return;
    }
    let found = this.content.find(c => c.nickname === this.valueLocal);
    this.value = found?.id;
  }

  contentChanged() {
    if(! this.content?.find(c => c.id === this.value)) {
      this.clear();
    }
  }

  valueChanged() {
    if(!this.content || this.value === undefined) {
      return;
    }
    this.valueLocal = this.clientName;
  }

  get clientName() {
    let found = this.content?.find(c => c.id === this.value);
    return found?.nickname || "";
  }

  clear() {
    this.valueLocal = "";
    this.value = undefined;
  }
}
