import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { UIPricingGroupRowClass } from 'pos/pricing-group-edit';
import { IdName, MyHttpApi, PricingGroupSpecifyRequest, PortionSize, SalesChannel, SalesRestriction } from 'utils/api';

@autoinject
export class PricingGroupRowsCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) rows: UIPricingGroupRowClass[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) id: string = "";
  @bindable({ defaultBindingMode: bindingMode.toView }) client?: IdName;
  @bindable({ defaultBindingMode: bindingMode.toView }) readOnly: boolean = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) specify?: (req: PricingGroupSpecifyRequest) => void;
  @bindable({ defaultBindingMode: bindingMode.toView }) addRow?: (clientId?: number) => void;
  @bindable({ defaultBindingMode: bindingMode.toView }) portionSizeList: PortionSize[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) salesChannelList: SalesChannel[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) salesRestrictionList: SalesRestriction[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) locationList: Location[] = [];

  constructor(private api: MyHttpApi) { }

  maybeAddRow() {
    if (this.addRow) {
      this.addRow(this.client?.id);
    }
  }

  @computedFrom("rows.length", "client")
  get rowList() {
    return this.rows.filter(r => {
      return this.client?.id === r.row.clientId;
    });
  }

  deleteRow(x: UIPricingGroupRowClass) {
    this.rows = this.rows.filter(srt => srt !== x);
  }

  deleteRestriction(x: UIPricingGroupRowClass) {
    this.rows = this.rows.filter(srt => srt !== x);
  }

}
