// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iso3166-country button.delete {
  color: #000;
  background-color: #FFF !important;
  padding: 0.4em 0.6em;
  margin: 0;
  border-left: 1px solid #888;
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
}
iso3166-country > span {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/iso3166-country.less"],"names":[],"mappings":"AAAA;EAEE,WAAA;EACA,iCAAA;EACA,oBAAA;EACA,SAAA;EACA,2BAAA;EACA,0BAAA;EACA,6BAAA;AAAF;AARA;EAYE,aAAA;AADF","sourcesContent":["iso3166-country {\n\tbutton.delete {\n\t\tcolor: #000;\n\t\tbackground-color: #FFF !important;\n\t\tpadding: 0.4em 0.6em;\n\t\tmargin: 0;\n\t\tborder-left: 1px solid #888;\n\t\tborder-top: 1px solid #888;\n\t\tborder-bottom: 1px solid #888;\n\t}\n\n\t>span {\n\t\tdisplay: flex;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
