import { autoinject, bindable, bindingMode } from 'aurelia-framework';

let listId = 0;

@autoinject
export class BelAuAutoCompleteCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: string;
  @bindable({ defaultBindingMode: bindingMode.toView }) content?: string[];
  @bindable({ defaultBindingMode: bindingMode.toView }) size = 1;
  @bindable({ defaultBindingMode: bindingMode.toView }) placeholder?: string = "";
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) choose = (param: { value: string }) => {/* no warn */};
  @bindable({ defaultBindingMode: bindingMode.toView }) focused = (param: {}) => { /* no warn */ };

  private listId: string;

  constructor(private element: Element) {
    this.listId = "list" + ++listId;
  }

  onInput() {
    let value = this.element.getElementsByTagName("input")[0].value;
    if (this.content) {
      let found = this.content.find(choice => value === choice);
      if(found) {
        this.choose({value});
      }
    }
  }

  focusStart() {
    let value = this.element.getElementsByTagName("input")[0].value;
    this.focused({ value });
  }

  focusEnd() {
    let value = this.element.getElementsByTagName("input")[0].value;
    if (this.content && value) {
      for (let choice of this.content) {
        if(choice.toLowerCase() === value.toLowerCase()) {
          value = this.value = choice;
          break;
        }
        if (choice.startsWith(value)) {
          value = this.value = choice;
        }
      }
    }
    this.choose({ value });
  }

  attached() {
    if (this.content) {
      this.content.forEach(c => {
        if (!this.size || this.size < c.length && c.length > 1) {
          this.size = c.length;
        }
      });
    }
  }
}
