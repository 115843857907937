// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `bel-au-html-select-multi {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/bel-au-html-select-multi/component.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8CAAA;EACA,gBAAA;AADF","sourcesContent":["@import (optional) \"../styles/variables.less\";\n\nbel-au-html-select-multi {\n  display: grid;\n  grid-template-columns: max-content max-content;\n  column-gap: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
