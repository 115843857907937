import { bindable, bindingMode, computedFrom } from 'aurelia-framework';

interface GenericElement {
  id: string;
  deleteTime?: Date;
}

export class BelAuHtmlSelectCustomElement<T extends GenericElement> {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: string;
  @bindable({ defaultBindingMode: bindingMode.toView }) class?: string;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) content: T[] = [];
  /* Extra allowed item, usually references and old, deleted value. Allow save if unchanged, but otherwise not visible for the list */
  @bindable({ defaultBindingMode: bindingMode.toView }) extra?: T;
  @bindable({ defaultBindingMode: bindingMode.toView }) placeholder?: string;
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) size?: number;
  @bindable({ defaultBindingMode: bindingMode.toView }) showPlaceholder?: boolean = true;
  @bindable({ defaultBindingMode: bindingMode.oneTime }) labelKey = "name";

  // Add support for extra items.
  // Item is already leaning to the fact, that there is an id
  // Only add this is if it deleted and not in the list and it is the current selected value
  @computedFrom("extra", "content", "value")
  get fullContent() {
    if (this.extra && this.content.indexOf(this.extra) === -1 && this.value === this.extra.id && this.extra.deleteTime) {
      return [...this.content, this.extra];
    }
    return this.content;
  }
}
